import React from 'react';
import {useTranslation} from "react-i18next";

export const AppFooter = () => {
    const {t} = useTranslation();

    return (
        <div className="footer flex-shrink-0">
            <div className="container-fluid container-lg">
                <div className="row">
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={process.env.PUBLIC_URL + '/logo.jpg'} alt="Resort Apartamenty Klifowa Rewal"/>
                            </div>
                            <div className="ml-2">
                                <p className="lead mb-1">{t('footer.footerAddress1')}</p>
                                <p className="mb-0">{t('footer.footerAddress2')}</p>
                                <p className="mb-1">{t('footer.footerAddress3')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <hr/>
                        <p className="small">
                            {t('footer.footerCopyrights')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
